import type {Portfolio} from "~/common/interfaces.js";
import {endpoints} from "~/constants/endpoints.js";
import {sprintf} from 'sprintf-js';
import {$useApiFetch, $useFetch} from "~/composables/useApiFetch";
import {logger} from "@nuxt/kit";

export const getPortfolioList = async (): Promise<any> => {
    const isLoading = useIsLoadingStore();
    const profileStore = useUserProfileStore()
    isLoading.set(true);

    if(profileStore.Portfolios.value) return profileStore.Portfolios.value
    return await $useApiFetch<Portfolio[]>(endpoints.master.portfolio.list, {
        method: 'GET',
    }).then((response) => {
        profileStore.setPortfoliosList(response)
        return response
    }).finally(() => isLoading.set(false))
}

export const getPortfolioDetail = async (id: number | string) => {
    const endpoint = sprintf(endpoints.master.portfolio.detail, id)
    return await $useApiFetch<Portfolio>(endpoint, {
        method: 'GET',
    })
}

export const promoRegister = async (data: any) => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);

    return await $useApiFetch(endpoints.master.promo_register, {
        baseURL: 'https://user.yourservice.ae/api', //TODO: Временная мера
        method: 'POST',
        body: data
    }).finally(() => isLoading.set(false));
}

export const portfolioUpdate = async (id: number | string, data: any) => {
    const endpoint = sprintf(endpoints.master.portfolio.update, id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch<Portfolio>(endpoint, {
        method: 'PUT',
        body: data
    }).finally(() => isLoading.set(false))
}



export const portfolioCreate = async (data: any) => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch<Portfolio>(endpoints.master.portfolio.create, {
        method: 'POST',
        body: data
    }).finally(() => isLoading.set(false))
}

export const deletePortfolio = async (portfolioId: number | string) => {
    const endpoint = sprintf(endpoints.master.portfolio.delete, portfolioId)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'DELETE',
    }).finally(() => isLoading.set(false))
}

export const deletePortfolioVideo = async (portfolioId: number | string, videoId: number | string) => {
    const endpoint = sprintf(endpoints.master.portfolio.videoDelete, portfolioId, videoId)
    return await $useApiFetch(endpoint, {
        method: 'DELETE',
    })
}

export const deletePortfolioPhoto = async (portfolioId: number | string, imageId: number | string) => {
    const endpoint = sprintf(endpoints.master.portfolio.photoDelete, portfolioId, imageId)
    return await $useApiFetch(endpoint, {
        method: 'DELETE',
    })
}

export const getMasterTaskList = async (params?: Object): Promise<any> => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoints.master.task.list, {
        method: 'GET',
        ...params
    }).finally(() => isLoading.set(false))
}

export const getTaskList = async (params?: Object): Promise<any> => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoints.task.search, {
        method: 'GET',
        ...params
    }).finally(() => isLoading.set(false))
}

export const getTaskListForCatalog = async (params?: Object): Promise<any> => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoints.task.BySpec, {
        method: 'GET',
        ...params
    }).finally(() => isLoading.set(false))
}

export const getTaskFiltersForCatalog = async (id: number | string): Promise<any> => {
    const endpoint = sprintf(endpoints.task.filter, id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'GET',
    }).finally(() => isLoading.set(false))
}

export const getTaskFiltersForProfile = async (): Promise<any> => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoints.task.profile_filter, {
        method: 'GET',
    }).finally(() => isLoading.set(false))
}

export const getTaskDetail = async (id: number | string): Promise<any> => {
    const endpoint = sprintf(endpoints.task.detail, id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'GET',
    }).finally(() => isLoading.set(false))
}


export const getMasterSubscription = async (props?: Object): Promise<any> => {
    return await $useFetch(endpoints.master.subscription, {
        method: 'GET',
        ...props
    })
}

export const subscriptionUpgrade = async (data: any) => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch<Portfolio>(endpoints.master.subscription_upgrade, {
        method: 'POST',
        body: data
    }).finally(() => isLoading.set(false))
}
export const taskResponse = async (task_id: number | string): Promise<any> => {
    const endpoint = sprintf(endpoints.task.response, task_id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'POST',
    }).finally(() => isLoading.set(false))
}

export const acceptTask = async (task_id: number) => {
    const endpoint = sprintf(endpoints.master.task.accept, task_id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'POST',
    }).finally(() => isLoading.set(false))
}

export const submitTask = async (task_id: number) => {
    const endpoint = sprintf(endpoints.master.task.submit, task_id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'POST',
    }).finally(() => isLoading.set(false))
}

export const completeTask = async (task_id: number) => {
    const endpoint = sprintf(endpoints.master.task.complete, task_id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'POST',
    }).finally(() => isLoading.set(false))
}

export const refuseTask = async (task_id: number) => {
    const endpoint = sprintf(endpoints.master.task.refuse, task_id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'POST',
    }).finally(() => isLoading.set(false))
}

export const getReciept = async (task_id: number) => {
    const endpoint = sprintf(endpoints.master.task.reciept, task_id)
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoint, {
        method: 'GET',
    }).finally(() => isLoading.set(false))
}